@import "styles/_colors";
.add-product {
  .page-title {
    margin-bottom: 10px;
  }
  .add-action {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    box-shadow: 0px 0px 10px $color-shadow-default;
    .icon {
      font-size: 64px;
    }
    &.barcode {
      background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    &.from {
      background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    }
  }
}
