/**
 * UI Initiative Stories Slider 1.0.0
 *
 * Instagram-like Stories Slider Made With Swiper
 *
 * https://uiinitiative.com
 *
 * Copyright 2022-2024 UI Initiative
 *
 * Released under the UI Initiative Regular License
 *
 * Released on: July 9, 2024
 */

:root {
  --stories-slider-z-shadows: 999;
  --stories-slider-z-pagination: 50;
  --stories-slider-z-navigation: 200;
  --stories-slider-z-user: 300;
  --stories-slider-z-actions: 300;
  --stories-slider-z-content: 100;
  --stories-slider-slide-bg-color: #000;
}

.stories-slider {
  padding-top: 44px;
  padding-bottom: 44px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  > .swiper > .swiper-wrapper {
    > .swiper-slide:not(.swiper-slide-visible, .swiper-slide-active) {
      opacity: 0;
    }
  }
  &.stories-slider-animating > .swiper > .swiper-wrapper > {
    .swiper-slide-prev,
    .swiper-slide-next,
    .swiper-slide-active {
      opacity: 1;
    }
  }
  .swiper {
    height: 100%;
    width: 100%;
    .swiper {
      .swiper-slide {
        border-radius: 8px;
        overflow: hidden;
        background-color: var(--stories-slider-slide-bg-color);
      }
    }
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      z-index: var(--stories-slider-z-shadows) !important;
    }
    .swiper-slide-shadow-left {
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.5)
      );
    }
    .swiper-slide-shadow-right {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.5)
      );
    }
  }
  &.stories-slider-perspective {
    .swiper .swiper,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      transform: translate3d(0, 0, calc(-1 * var(--swiper-cube-translate-z)));
      transform-origin: 0% 0% calc(-1 * var(--swiper-cube-translate-z));
    }
  }
}
.stories-slider-button {
  position: absolute;
  z-index: var(--stories-slider-z-navigation);
  top: 0;
  height: 100%;
  user-select: none;
  -webkit-user-select: none;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition-duration: 200ms;
  }
  &:active::before {
    opacity: 0.25;
  }
  &-prev {
    left: 0;
    width: 30%;
    &::before {
      background-image: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
    }
  }
  &-next {
    right: 0;
    width: 70%;
    &::before {
      background-image: linear-gradient(to left, #000, rgba(0, 0, 0, 0));
    }
  }
}
.stories-slider-pagination {
  position: absolute;
  left: 8px;
  right: 8px;
  top: 8px;
  display: flex;
  justify-content: space-between;
  z-index: var(--stories-slider-z-pagination);
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  &-bullet {
    width: 100%;
    flex-shrink: 10;
    border-radius: 999px;
    height: 2px;
    background: rgba(255, 255, 255, 0.35);
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35);
    span {
      position: absolute;
      background: #fff;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 999px;
      transform: translateX(-100%);
      transition-timing-function: linear;
    }
    &-viewed span {
      transform: translateX(0%);
      transition-duration: 0ms !important;
    }
  }
  &-bullet + &-bullet {
    margin-left: 2px;
  }
}
a.stories-slider-user,
.stories-slider-user a {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition-duration: 200ms;
  &:active {
    opacity: 0.55;
  }
}
.stories-slider-user {
  display: flex;
  align-items: center;
  position: absolute;
  top: 18px;
  left: 12px;
  height: 60px;
  z-index: var(--stories-slider-z-user);
  color: #fff;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  &-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 12px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-name {
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    margin-right: 12px;
    color: #fff;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  }
  &-area {
    font-size: 10px;
    line-height: 1.0;
    font-weight: normal;
    color: #fff;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  }
  &-link {
    font-size: 14px;
    line-height: 1.2;
    font-weight: bold;
  }
}
.stories-slider-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 10px;
  top: 18px;
  height: 32px;
  z-index: var(--stories-slider-z-actions);
  transition-duration: 200ms;
  user-select: none;
  -webkit-user-select: none;
}
.stories-slider-close-button {
  margin-left: 18px;
  width: 22px;
  height: 22px;
  appearance: none;
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  &::before,
  &::after {
    content: '';
    width: 125%;
    height: 2px;
    background: #fff;
    border-radius: 999px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35);
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:active {
    opacity: 0.55;
  }
}
.stories-slider-content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: var(--stories-slider-z-content);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-position: center;
    display: block;
    border: none;
    outline: none;
    background: none;
    background-color: transparent;
  }
  > img {
    object-fit: cover;
  }
  > video {
    object-fit: contain;
  }
}
