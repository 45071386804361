@import "./colors.scss";
@import url('https://fonts.googleapis.com/css?family=Poppins');

$font-family-base: "Arial", sans-serif;

// TODO: [EM-215] Improve font-size variable naming
$font-size-11: 11px;
$font-size-13: 13px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-36: 36px;
$font-size-48: 48px;

$font-size-x-small: 9px;
$font-size-small: 12px;
$font-size-default: 14px;
$font-size-large: 16px;
$font-size-x-large: 18px;

p,
.text {
  font-size: $font-size-default;
  line-height: 20px;
  font-weight: 400;
  color: $color-text-default;

  &.normal {
    // For reset
    font-size: $font-size-default;
    line-height: 20px;
    font-weight: 400;
    color: $color-text-default;
  }

  &.small {
    font-size: $font-size-small;
    line-height: 18px;
  }

  &.large {
    font-size: $font-size-large;
    line-height: 22px;
  }

  &.x-large {
    font-size: $font-size-x-large;
    line-height: 24px;
  }

  &.thin {
    font-weight: 300;
  }

  &.bold {
    font-weight: 700;
  }

  &.thick {
    font-weight: 900;
  }

  &.light {
    color: $color-text-light;
  }

  &.medium {
    color: $color-text-medium;
  }

  &.dark {
    color: $color-text-dark;
  }

  &.i {
    font-style: italic;
  }
}

h1 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

h2 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

h3 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

h4 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

h5 {
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

.thick {
  font-weight: 900;
}

label {
  line-height: 18px;
  display: inline-block;
}
